(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/game-guide/assets/javascripts/article-page.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/game-guide/assets/javascripts/article-page.js');
"use strict";


const {
  useState,
  useEffect
} = React;
const {
  loadArticle
} = svs.content.simple_article.contentLoader;
const {
  Loader
} = svs.components.ReactLoader;
const {
  log
} = svs.core;
const logger = log.getLogger('component:load-article');
const Icon = svs.ui.ReactIcon;
const EmptyView = () => React.createElement("div", {
  className: "game-guide-oddset-empty align-center margin-top-4"
}, React.createElement(Icon, {
  color: "fc-oddset",
  icon: "rest",
  size: "900"
}), React.createElement("p", null, "Inneh\xE5llet kunde inte visas, prova igen"));
const ArticlePage = _ref => {
  let {
    articleId
  } = _ref;
  const [isLoading, setIsLoading] = useState(false);
  const [articleContent, setArticleContent] = useState(null);
  useEffect(() => {
    if (articleId) {
      setIsLoading(true);
      const options = {
        url: new svs.content.shared.ContentUrlBuilder("/content/2/id/".concat(articleId)).build()
      };
      loadArticle(options, (err, article) => {
        if (err) {
          setArticleContent(null);
          logger.warn('Error retrieving spelguide', err);
        } else if (!err && !article) {
          setArticleContent(null);
          logger.warn('No spelguide found with provided id');
        } else {
          setArticleContent(article);
        }
        setIsLoading(false);
      });
    }
  }, [articleId]);
  if (isLoading) {
    return React.createElement(Loader, null);
  }
  if (!articleContent) {
    return React.createElement(EmptyView, null);
  }

  return React.createElement("div", {
    className: "oddset-game-guide-article f-content"
  }, React.createElement("h2", {
    dangerouslySetInnerHTML: {
      __html: articleContent.title
    }
  }), React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: articleContent.ingress
    }
  }), React.createElement("div", {
    dangerouslySetInnerHTML: {
      __html: articleContent.body
    }
  }));
};
setGlobal('svs.oddset_kambi.components.game_guide.ArticlePage', ArticlePage);

 })(window);