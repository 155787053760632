(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/game-guide/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/game-guide/assets/javascripts/constants.js');
"use strict";


const {
  gameGuideConfig
} = svs.oddset_kambi.data;
const gameGuideRoutes = [{
  path: '/',
  article: null,
  label: 'Så spelar du'
}, {
  path: '/om',
  article: gameGuideConfig.articleAbout,
  label: 'Om Oddset'
}, {
  path: '/betbuilder',
  article: gameGuideConfig.articleBetbuilder,
  label: 'Bet Builder'
}, {
  path: '/systemspel',
  article: gameGuideConfig.articleSystem,
  label: 'Systemspel'
}, {
  path: '/speltyper',
  article: gameGuideConfig.articleGameTypes,
  label: 'Speltyper'
}, {
  path: '/cash-out',
  article: gameGuideConfig.articleCashOut,
  label: 'Cash Out'
}];
setGlobal('svs.oddset_kambi.components.game_guide.constants', {
  gameGuideRoutes
});

 })(window);