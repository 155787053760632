(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/oddset-kambi/components/game-guide/assets/javascripts/start-page.js') >= 0) return;  svs.modules.push('/modules/oddset-kambi/components/game-guide/assets/javascripts/start-page.js');
"use strict";

const {
  cdn
} = svs.core.config.data.svsconfig;
const StartPage = () => React.createElement("div", {
  className: "game-guide-start-page"
}, React.createElement("h1", {
  className: "game-guide-start-page__heading"
}, "Oddset \xE4r f\xF6r dig som vill lista ut om det blir hemmaseger, oavgjort eller bortaseger \u2013 p\xE5 upp till 16 matcher."), React.createElement("p", {
  className: "game-guide-start-page__paragraph"
}, "V\xE4lj mellan sporter som fotboll, hockey, trav, innebandy, skidskytte, e-sport och underh\xE5llning som till exempel Melodifestivalen."), React.createElement("img", {
  alt: "Svenska spel",
  className: "margin-bottom-3 margin-top-3 game-guide-start-page__image",
  src: "".concat(cdn, "/images/oddset/components/game-guide/spelguideoddset1.svg")
}), React.createElement("p", {
  className: "game-guide-start-page__paragraph"
}, "Du kan ocks\xE5 spela p\xE5 vem som g\xF6r f\xF6rsta m\xE5let, vilka som leder i halvtid eller hur m\xE5nga m\xE5l lagen g\xF6r."), React.createElement("img", {
  alt: "Svenska spel",
  className: "margin-bottom-3 margin-top-3 game-guide-start-page__image",
  src: "".concat(cdn, "/images/oddset/components/game-guide/spelguideoddset2.svg")
}), React.createElement("p", {
  className: "game-guide-start-page__paragraph"
}, "Sj\xE4lvklart kan du ocks\xE5 kombinera f\xF6rtids- och livespel."), React.createElement("img", {
  alt: "Svenska spel",
  className: "margin-bottom-3 margin-top-3 game-guide-start-page__image",
  src: "".concat(cdn, "/images/oddset/components/game-guide/spelguideoddset3.svg")
}), React.createElement("p", {
  className: "game-guide-start-page__paragraph"
}, "Om du vinner s\xE5 multipliceras oddsen p\xE5 de valda matcherna med varandra, d\xE4refter med storleken p\xE5 insatsen."));
setGlobal('svs.oddset_kambi.components.game_guide.StartPage', StartPage);

 })(window);